<!--电梯模块--布局主页-->
<template>
  <div id="elevatorindex">
    <div class="header" v-show="this.$route.meta.hidden">
      <my-indextitle :imgurl="imgurl">{{ title }}</my-indextitle>
      <my-navdar :list="list"></my-navdar>
    </div>
    <div class="main">
      <keep-alive :exclude="cancelCache">
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import indexTitle from "../../components/Title/indexTitle";
import Navdar from "../../components/Navdar/horizontal";

export default {
  data() {
    return {
      cancelCache: ["reportData", "runningState"],
      imgurl: require("../../../public/images/moduleimg/elevator.png"),
      list: [],
    };
  },
  watch: {
    //   获取导航栏数据
    "$store.getters.loading": {
      handler: function (newValue, oldValue) {
         const value = this.$route.query.Code;
          if (!newValue) {
            this.$store.commit("menu/SITE_MENU", value);
            this.list = this.$store.getters.siteMenu;;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  // 获取名称
  computed: {
    title: function () {
      let name = this.$store.getters.siteName;
      return name;
    },
  },
  // 导航栏数据初始化
  mounted() {
    const value = this.$store.getters.loading;
    const Code = this.$route.query.Code;
    if (!value) {
      this.$store.commit("menu/SITE_MENU", Code);
      this.list = this.$store.getters.siteMenu;
    }
  },
  components: {
    "my-indextitle": indexTitle,
    "my-navdar": Navdar,
  },
};
</script>

<style scoped>
#elevatorindex {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px #bdbcbc;
  background: #ffffff;
  width: calc(100% - 40px);
  height: calc(100% - 30px);
  overflow: hidden;
}
.main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.header {
  height: 100px;
  padding: 0px 20px 0px 20px;
}
</style>